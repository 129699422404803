import React, { useEffect, useMemo, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  Tooltip,
  Button,
  Modal
} from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import InventoryIcon from '@mui/icons-material/Inventory';
import QuantitiesIcon from '@mui/icons-material/BarChart';
import LinearProgress from '@mui/material/LinearProgress';
import { useAppContext } from './AppContext';
import {Product} from './interfaces';
import * as Tone from 'tone';
import { useAuth } from './Context/AuthContext';
import  {DocumentGenerator}  from './DocumentData'; 
type Carton = { name: string, items: Product[], totalPoidsCarton?: number; };
type Palette = { name: string, items: (Product | Carton)[] };

interface ValidationResult {
  isValid: boolean;
  incompletedCartons: {
    name: string;
    source: 'carton' | 'palette' | 'global';
    paletteName?: string;
    message?: string;
  }[];
}

const playSuccessSound = () => {
  const synth = new Tone.Synth().toDestination();
  synth.triggerAttackRelease("E4", "8n");
};

const Header: React.FC = () => {
  const {  warehouseHeaders, items, handleReset,allContent, setAllContent, paletteLists, cartonLists, showUnitCost, poidsGlobalTotal, isValidationComplete, setIsValidationComplete} = useAppContext();
  const [totalInitialQuantity, setTotalInitialQuantity] = useState(0);
  const [totalUpdatedQuantity, setTotalUpdatedQuantity] = useState(0);
  const [progressionFigures, setProgressionFigures] = useState(0);
  const [open, setOpen] = useState(false);
  const { user } = useAuth();
  const { generateDocument } = DocumentGenerator();
  const [, setIsGenerating] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidationResult['incompletedCartons']>([]);
  const [successSoundPlayed, setSuccessSoundPlayed] = useState<boolean>(false);
  // let successSoundPlayed = false;
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    
  };

  useEffect(() => {
    const initialQuantity = items.reduce((total, item) => total + item.Quantity, 0);
    setTotalInitialQuantity(initialQuantity);
  }, [items]);


  useEffect(() => {
    const updatedQuantity = items.reduce((total, item) => total + item.ActualQuantity, 0);
    setTotalUpdatedQuantity(updatedQuantity);
  }, [items]);


  useEffect(() => {
    if (totalInitialQuantity === 0) {
      setProgressionFigures(0);
    } else {
      const currentProgress = (totalUpdatedQuantity / totalInitialQuantity) * 100;
      const progressFigure = parseFloat((100 - currentProgress).toFixed(2));
      setProgressionFigures(progressFigure);
    }
  }, [totalInitialQuantity, totalUpdatedQuantity]);

  const statistics = useMemo(() => {
    
    const uniqueNoBls = new Set(warehouseHeaders.map(header => header.No)).size;
    const uniqueItems = new Set(items.map(item => item.Code_EAN)).size;
    const totalQuantity = items.reduce((total, item) => total + item.Quantity, 0);
    return {
      bpCount: uniqueNoBls,
      itemCount: uniqueItems,
      totalQuantity,
    };
  }, [items, warehouseHeaders]);


  
  const checkCartonPoids = (carton: Carton): boolean => {
    if (carton.totalPoidsCarton === undefined) {
      console.log(`Vérification poids carton "${carton.name}":`, {
        poids: 'non défini',
        isValid: false,
        message: 'Poids non défini'
      });
      return false;
    }
  
    const isValid = carton.totalPoidsCarton > 0;
    
    console.log(`Vérification poids carton "${carton.name}":`, {
      poids: carton.totalPoidsCarton,
      isValid,
      message: isValid ? 'Validation OK' : 'Le poids doit être supérieur à 0'
    });
    
    return isValid;
  };
  
  
  const validateCartons = (cartons: Carton[]): ValidationResult => {
    console.log('Début validation des cartons libres:', cartons);
    const incompletedCartons: ValidationResult['incompletedCartons'] = [];
    
    cartons.forEach(carton => {
      if (!checkCartonPoids(carton)) {
        console.log(`Carton invalide détecté: ${carton.name}`);
        incompletedCartons.push({
          name: carton.name,
          source: 'carton'
        });
      }
    });
  
    const result = {
      isValid: incompletedCartons.length === 0,
      incompletedCartons
    };
    console.log('Résultat validation cartons:', result);
    return result;
  };
  
  const validatePaletteCartons = (palettes: Palette[]): ValidationResult => {
    console.log('Début validation des cartons dans palettes:', palettes);
    const incompletedCartons: ValidationResult['incompletedCartons'] = [];
    
    palettes.forEach(palette => {
      console.log(`Vérification palette: ${palette.name}`);
      palette.items.forEach(item => {
        if ('items' in item) {
          if (!checkCartonPoids(item)) {
            incompletedCartons.push({
              name: item.name,
              source: 'palette',
              paletteName: palette.name
            });
          }
        }
      });
    });
  
    const result = {
      isValid: incompletedCartons.length === 0,
      incompletedCartons
    };
    return result;
  };
  
  const validateAll = useMemo((): ValidationResult => {
    console.log('Début validation globale:', {
      itemsCount: items.length,
      cartonsCount: cartonLists.length,
      palettesCount: paletteLists.length
    });
  
    if (paletteLists.length > 0 && poidsGlobalTotal <= 0) {
      return {
          isValid: false,
          incompletedCartons: [{
              name: "Poids Global Total",
              source: 'global',
              message: "Le Poids Global Total doit être complété obligatoirement"
          }]
      };
  }
    const itemsQuantityCheck = items.every(item => item.ActualQuantity === 0);
    console.log('Détail vérification items:', {
      itemsQuantityCheck,
      itemsNonZero: items.filter(item => item.ActualQuantity !== 0).map(item => ({
        id: item.id,
        quantity: item.ActualQuantity
      }))
    });
  

    const cartonValidation = validateCartons(cartonLists);
    console.log('Résultat validation cartons:', {
      isValid: cartonValidation.isValid,
      incompletedCount: cartonValidation.incompletedCartons.length,
      details: cartonValidation.incompletedCartons
    });
  

    const paletteValidation = validatePaletteCartons(paletteLists);
    console.log('Résultat validation palettes:', {
      isValid: paletteValidation.isValid,
      incompletedCount: paletteValidation.incompletedCartons.length,
      details: paletteValidation.incompletedCartons
    });
  
    const allIncompletedCartons = [
      ...cartonValidation.incompletedCartons,
      ...paletteValidation.incompletedCartons
    ];
  
    const result = {
      isValid: itemsQuantityCheck && cartonValidation.isValid && paletteValidation.isValid,
      incompletedCartons: allIncompletedCartons
    };
  
    console.log('Résultat final validation:', {
      isValid: result.isValid,
      conditions: {
        itemsOk: itemsQuantityCheck,
        cartonsOk: cartonValidation.isValid,
        palettesOk: paletteValidation.isValid
      },
      incompletedCount: allIncompletedCartons.length
    });
  
    return result;
  }, [items, cartonLists, paletteLists,poidsGlobalTotal]);
  
  useEffect(() => {
    console.log('État actuel validation:', {
      isValid: validateAll.isValid,
      errorCount: validateAll.incompletedCartons.length,
      successSoundPlayed,
      isValidationComplete
    });
  
    setValidationErrors(validateAll.incompletedCartons);
    
    if (validateAll.isValid && !successSoundPlayed) {
      console.log('Conditions pour activation du bouton:', {
        validateAllIsValid: validateAll.isValid,
        noErrors: validateAll.incompletedCartons.length === 0,
        soundNotPlayed: !successSoundPlayed
      });
  
      playSuccessSound();
      setSuccessSoundPlayed(true);
      setIsValidationComplete(true);
      
    } else if (!validateAll.isValid) {
      setSuccessSoundPlayed(false);
      setIsValidationComplete(false);
    }
  }, [validateAll, successSoundPlayed]);
  

  useEffect(() => {
    if (totalInitialQuantity === 0) {
      setProgressionFigures(0);
    } else {
      const currentProgress = (totalUpdatedQuantity / totalInitialQuantity) * 100;
      const progressFigure = parseFloat((100 - currentProgress).toFixed(2));
      setProgressionFigures(progressFigure);
    }
  }, [totalInitialQuantity, totalUpdatedQuantity]);


  const handleGenerateDocument = async () => {
    setIsGenerating(true);
    const sortItems = (items: Product[]) => {
      return items.sort((a, b) => {
        if (a.Bin_Code && b.Bin_Code) {
          return a.Bin_Code.localeCompare(b.Bin_Code);
        }
        return 0;
      });
    };

    const sortedCartonLists = allContent.cartonLists.map(cartonList => ({
      ...cartonList,
      items: sortItems(cartonList.items),
    }));

    const sortedPaletteLists = allContent.paletteLists.map(paletteList => ({
      ...paletteList,
      items: paletteList.items.map(item => {
        if ('items' in item) {
          return {
            ...item,
            items: sortItems(item.items),
          };
        }
        return item;
      }).sort((a, b) => {
        const aBinCode = 'Bin_Code' in a ? a.Bin_Code : undefined;
        const bBinCode = 'Bin_Code' in b ? b.Bin_Code : undefined;
        if (aBinCode && bBinCode) {
          return aBinCode.localeCompare(bBinCode);
        }
        return 0;
      }),
    }));

    const sortedAllContent = {
      cartonLists: sortedCartonLists,
      paletteLists: sortedPaletteLists,
    };

  

    const destinationNo = items.length > 0 ? items[0].Destination_No ?? '' : '';
    const userInitials = user && user.displayName
      ? user.displayName.split(' ').map(word => word.charAt(0).toUpperCase()).join('.')
      : 'Non renseigné';

    const formatDate = (date: Date) => {
      return date.toISOString();
    };

    const date = new Date();
    const formattedDate = formatDate(date);

    const documentDataPdf = {
      format: 'pdf' as 'excel' | 'pdf',
      data: {
        totalWeight: poidsGlobalTotal,
        destinationNo,
        userInitials,
        date: formattedDate,
        showUnitCost: showUnitCost, 
        allContent: sortedAllContent,
      },
    };
    const documentDataExcel = {
      format: 'excel' as 'excel' | 'pdf',
      data: {
        totalWeight: poidsGlobalTotal,
        destinationNo,
        userInitials,
        date: formattedDate,
        showUnitCost: showUnitCost, 
        allContent: sortedAllContent,
      },
    };

    try {
      await generateDocument(documentDataExcel);
      const pdfBlob = await generateDocument(documentDataPdf);
  
      if (pdfBlob) {
        const pdfFilename = `${documentDataPdf.data.destinationNo}_${documentDataPdf.data.date}_GlobalPackingList.pdf`;

        const pdfUrl = window.URL.createObjectURL(pdfBlob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = pdfUrl;
        a.download = pdfFilename;
        document.body.appendChild(a);
        a.click();
    
        const pdfWindow = window.open(pdfUrl, '_blank');
        if (pdfWindow) {
          pdfWindow.onload = () => {
            setTimeout(() => {
              pdfWindow.print();
              window.URL.revokeObjectURL(pdfUrl);
            }, 1000); 
          };
        } else {
          console.warn("La fenêtre du PDF n'a pas pu être ouverte. Vérifiez les paramètres de blocage des popups.");
          window.URL.revokeObjectURL(pdfUrl);
        }
      }
    } catch (error) {
      console.error('Error generating documents:', error);
    } finally {
      setIsGenerating(false);
    }
  };


  return (
    <AppBar position="static" style={{ backgroundColor: '#E4007C' }}>
      <Toolbar style={{ padding: '10px 20px', display: 'flex', justifyContent: 'space-between' }}>
        <Box display="flex" alignItems="center">
          <img src="/tiloliLogo.png" alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            Packing List Generator
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" style={{ flex: 1, marginLeft: '20px', marginRight: '20px' }}>
          <Typography style={{ color: 'white', marginRight: '10px' }}>{totalInitialQuantity-totalUpdatedQuantity}</Typography>
          <LinearProgress
            variant="determinate"
            value={progressionFigures}
            style={{ width: '100%', height: '20px', borderRadius: '5px' }}
          />
          <Typography style={{ color: 'white', marginLeft: '10px' }}>{totalInitialQuantity}</Typography>
        </Box>
        <Box display="flex" alignItems="center">
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          Client: {items.length > 0 ? items[0].Destination_No : ''}
        </Typography>
        
          <Tooltip title="Number of BPs">
            <Box display="flex" alignItems="center" style={{ marginLeft: '20px' }}>
              <LocalShippingIcon style={{ marginRight: '5px', color: 'white' }} />
              <Typography style={{ color: 'white' }}>{statistics.bpCount}</Typography>
            </Box>
          </Tooltip>
          <Tooltip title="Number of Different Items">
            <Box display="flex" alignItems="center" style={{ marginLeft: '20px' }}>
              <InventoryIcon style={{ marginRight: '5px', color: 'white' }} />
              <Typography style={{ color: 'white' }}>{statistics.itemCount}</Typography>
            </Box>
          </Tooltip>
          <Tooltip title="Total Quantity of Items">
            <Box display="flex" alignItems="center" style={{ marginLeft: '20px' }}>
              <QuantitiesIcon style={{ marginRight: '5px', color: 'white' }} />
              <Typography style={{ color: 'white' }}>{statistics.totalQuantity}</Typography>
            </Box>
          </Tooltip>
          {/* <div>
          <Button
      variant="contained"
      style={{ 
        backgroundColor: 'rgb(167, 202, 237)', 
        color: 'white', 
        fontWeight: 'bold', 
        marginLeft: '20px' 
      }}
      onClick={() => handleGenerateDocument('excel')}
      disabled={isLoading}
    >
      Générer le document
    </Button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div> */}
{isValidationComplete ? (
        <Button
          variant="contained"
          style={{ 
            backgroundColor: 'rgb(167, 202, 237)', 
            color: 'white', 
            fontWeight: 'bold', 
            marginLeft: '20px' 
          }}
          onClick={() => handleGenerateDocument()}
        >
          Impression
        </Button>
      ) : (
      <Tooltip
        title={
          <div>
            <p>Impossible d'imprimer. Veuillez compléter :</p>
            <ul style={{ margin: 0, paddingLeft: '20px' }}>
              {validationErrors.map((error, index) => (
                <li key={index}>
                  {error.source === 'carton' && (
                    `Poids manquant pour le carton "${error.name}"`
                  )}
                  {error.source === 'palette' && (
                    `Poids manquant pour le carton "${error.name}" dans la palette "${error.paletteName}"`
                  )}
                  {error.source === 'global' && (
                    `Le Poids Global Total est obligatoire car vous avez des palettes`
                  )}
                </li>
              ))}
              {!items.every(item => item.ActualQuantity === 0) && (
                <li>Certains articles n'ont pas été traités</li>
              )}
            </ul>
          </div>
        }
      >
          <span>
            <Button
              variant="contained"
              style={{ 
                backgroundColor: '#cccccc', 
                color: 'white', 
                fontWeight: 'bold', 
                marginLeft: '20px',
                cursor: 'not-allowed'
              }}
              disabled
            >
              Impression
            </Button>
          </span>
        </Tooltip>
      )}


{/* <Button
          variant="contained"
          style={{ backgroundColor: 'rgb(167, 202, 237)', color: 'white', fontWeight: 'bold', marginLeft: '20px' }}
          onClick={async () => {
            await handleGlobalPrint();
          }}
        >
          Impression Globale
        </Button> */}
          <Button
            variant="contained"
            style={{ backgroundColor: 'rgb(167, 202, 237)', color: 'white', fontWeight: 'bold', marginLeft: '20px' }}
            onClick={handleOpen}
          >
            Nouvelle Liste
          </Button>
          <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box 
          sx={{ 
            position: 'absolute', 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)', 
            width: 400, 
            bgcolor: 'background.paper', 
            border: '2px solid #000', 
            boxShadow: 24, 
            p: 4 
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Êtes-vous sûr de vouloir tout réinitialiser ?
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="primary" onClick={() => handleReset(handleClose)}>              Oui
            </Button>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              Non
            </Button>
          </Box>
        </Box>
      </Modal>
        </Box>
      </Toolbar>
    </AppBar>
    
  );
};

export default Header;
